<template>
  <div>
    <Page :tabList="tabList"></Page>
  </div>
</template>
  
  <script>
import Page from "../../components/public/Page.vue";
export default {
  components: {
    Page,
  },
  data() {
    return {
      tabList: [
        { name: "企业自评自测", path: "/historyPage" },
        { name: "智能制造成熟度评估", path: "/intelligent" },
        { name: "自检表", path: "/selfchecksheet" },
        { name: "国家考核办法", path: "/measure" },
        { name: "审核员设置", path: "/censorset" },
      ],
    };
  },
};
</script>
  
  <style>
</style>